import { StoryblokComponent } from '@storyblok/react';
import React, { useEffect } from 'react';

import { usePreviewMode, useStoryblok } from '@b2c-web-marketing/shared/storyblok-sdk';
import { dehydrate } from '@tanstack/react-query';
import { componentRelations } from 'apps/urban/api/relations/relations';
import { queryClient } from 'apps/urban/context/ReactQueryProvider';
import { getDynamicItemsData } from 'apps/urban/utils/getDynamicComponent/getDynamicComponent';
import { GetStaticPaths } from 'next';
import Router from 'next/router';

import {
  getPage,
  getHeader,
  getFooter,
  getLocationModal,
  getTreatment,
  getCookieBanner,
  getLegal,
  getSalesforceChat,
  getSharedSeoSchemaItems,
  getBlogArticle,
  getBlogCategory,
} from '../../api';
import { PageItem } from '../../api/types/basic';
import { getFooterV2 } from '../../api/utils/getFooterV2';
import { getHeaderV2 } from '../../api/utils/getHeaderV2';
import { getSettings } from '../../api/utils/getSettings';
import { Layout } from '../../components/Layout/Layout';
import { getSlugFromParams } from '../../utils/getSlugFromParams';
import { getTemplateType } from '../../utils/getTemplateType';
import { getURLFromStoryblokLink } from '../../utils/getURLFromStoryblokLink/getURLFromStoryblokLink';
import { handlePageItemLoad } from '../../utils/handlePageItemLoad/handlePageItemLoad';
import { isLocalEnvHost } from '../../utils/isLocalEnvHost';
import { NEXT_ISR_REVALIDATE_SECONDS } from '../../utils/isr';

const COMPONENTS_PREVIEW_PAGE_SLUG = 'components-preview';

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', (url) => {
  window?.analytics?.page(url);
});

export function Index({
  data,
  preview,
  header,
  footer,
  salesforceChat,
  locationModal,
  cookieBanner,
  useV2Header,
  useV2Footer,
  keepTopMargin,
}) {
  usePreviewMode(preview);
  const story = useStoryblok<PageItem>(data, preview, handlePageItemLoad, componentRelations);

  useEffect(() => {
    // In production, we only show the preview page if it's in an iframe - this is for storyblok purposes.
    // otherwise, we hide it so real users don't see it.
    if (
      data.slug === COMPONENTS_PREVIEW_PAGE_SLUG &&
      typeof window !== 'undefined' &&
      process.env.NODE_ENV === 'production' &&
      (window === window.parent
        ? !isLocalEnvHost(window.location.host) && !window.location.host.includes('staging')
        : false)
    ) {
      window.location.pathname = '/';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data || !story?.content?.body) {
    return null;
  }

  return (
    <Layout
      header={header}
      footer={footer}
      salesforceChat={salesforceChat}
      metatags={data?.content?.metatags}
      locationModal={locationModal}
      cookieBanner={cookieBanner}
      noIndex={data?.content?.noIndex}
      noFollow={data?.content?.noFollow}
      canonical={data?.content?.canonical}
      useV2Header={useV2Header}
      useV2Footer={useV2Footer}
      keepTopMargin={keepTopMargin}
    >
      <StoryblokComponent blok={story.content} />
    </Layout>
  );
}

export const getStaticProps = async ({ locale, params, preview = false }) => {
  locale = locale === 'default' ? 'en-gb' : locale;

  const slug = getSlugFromParams({ params });
  const templateType = await getTemplateType({ params, locale: locale });

  let data;

  if (templateType === 'page') {
    data = await getPage({
      slug: `${locale}/${slug}`,
      relations: componentRelations,
    });
  } else if (templateType === 'treatment') {
    data = await getTreatment({
      slug: `${locale}/${slug}`,
      relations: componentRelations,
    });
  } else if (templateType === 'legal') {
    data = await getLegal({
      slug: `${locale}/${slug}`,
      relations: componentRelations,
    });
  } else if (templateType === 'blogArticle') {
    const res = await getBlogArticle({
      slug: `${locale}/${slug}`,
      relations: componentRelations
        .split(',')
        .filter((relation) => relation !== 'blogArticle.categories')
        .join(','),
    });
    if (res?.content?.externalUrl) {
      // We don't want to render blogs that have an external link
      data = null;
    } else {
      data = res;
    }
  } else if (templateType === 'blogCategory') {
    data = await getBlogCategory({
      slug: `${locale}/${slug}`,
      relations: componentRelations,
    });
  }

  if (!data) {
    return {
      notFound: true,
    };
  }

  const client = await getDynamicItemsData({ story: data, queryClient, locale });
  const dehydratedState = client ? dehydrate(client) : null;

  const settings = await getSettings({ slug: `${locale}/settings` });
  const headerV2Settings = settings?.content?.headerV2SettingsPerPage?.find(
    (setting) => getURLFromStoryblokLink(setting.link) === `${locale}/${slug}`
  );

  const useV2Header = settings?.content?.useV2Header || false;
  const useV2Footer = settings?.content?.useV2Footer || false;
  const keepTopMargin =
    useV2Header && (headerV2Settings?.keepTopMargin || settings?.content?.keepTopMargin);
  const keepSolidBackground =
    useV2Header &&
    (headerV2Settings?.keepSolidBackground || settings?.content?.keepSolidBackground);

  const header = useV2Header
    ? await getHeaderV2({ slug: `${locale}/header-v2` })
    : await getHeader({ slug: `${locale}/header` });
  const footer = useV2Footer
    ? await getFooterV2({ slug: `${locale}/footer-v2`, relations: componentRelations })
    : await getFooter({ slug: `${locale}/footer` });
  const locationModal = await getLocationModal({ slug: `${locale}/location-modal` });
  const cookieBanner = await getCookieBanner({ slug: `${locale}/cookie-consent` });
  const salesforceChat = await getSalesforceChat({ slug: `${locale}/salesforce-chat` });
  const sharedseoschemaItems = await getSharedSeoSchemaItems({
    startsWith: `${locale}/shared-schemas`,
  });

  console.log(`getStaticProps ${slug} ${locale} revalidate: ${NEXT_ISR_REVALIDATE_SECONDS}`);

  return {
    props: {
      data,
      sharedseoschemaItems: sharedseoschemaItems || null,
      cookieBanner: cookieBanner ? cookieBanner.content : null,
      footer: footer.content,
      header: { ...header?.content, keepSolidBackground },
      salesforceChat: salesforceChat ? salesforceChat.content : null,
      locationModal: locationModal.content,
      preview,
      dehydratedState,
      useV2Header,
      useV2Footer,
      keepTopMargin,
    },
    revalidate: NEXT_ISR_REVALIDATE_SECONDS,
  };
};

export const getStaticPaths: GetStaticPaths<{ slug: string }> = async () => ({
  paths: [], //indicates that no page needs be created at build time
  fallback: 'blocking', //indicates the type of fallback
});

export default Index;
